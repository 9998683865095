<div class="d-flex flex-column justify-content-between align-items-start h-100">
  <div class="d-flex align-items-center">
    <ion-icon class="sqr-lg mr-2" name="logo-x-actas"></ion-icon>
    <div>
      <div class="font-weight-bold">Actas</div>
      @if (isUpdateAvailable) {
        <div translate="appInfo.newVersionAvailable"></div>
      } @else {
        <div class="font-size-sm">v.{{ appVersion }}</div>
      }
      <div class="color-gray font-size-sm">
        <span translate="appInfo.platforms"></span>
        <span [innerText]="platforms"></span>
      </div>
    </div>
  </div>

<!--  @if (isUpdateAvailable) {-->
    <ion-button class="d-block d-md-inline-block mt-4"
                (click)="reloadApp()"
                translate="appInfo.upgradeToLatestVersion">
    </ion-button>
<!--  }-->
</div>
