import {JSONContent} from "@tiptap/core";
import {DocumentType} from "workspace-taxonomy/workspace-taxonomy.model";

export interface DynamicField {
  fieldId: string;
  fieldType: FieldDataType;
  fieldName?: string;
  fieldDescription?: string;
  isRequired?: boolean;
  isShownOnSummary?: boolean;
  // documentTypeId?: number;
  documentTypeGuid?: string;
  isSum?: boolean;
  hasMemory?: boolean;
  formula?: JSONContent;
  fieldValueOptions?: Array<{id: string, name: string}>;
  documentType?: DocumentType;
  numericDecimals?: number;
  layoutX?: number;
  layoutY?: number;
  layoutWidth?: number;
  layoutHeight?: number;
  measureUnit?: string;
  warningWhenChanged?: string;
}

export interface DynamicFieldValue {
  fieldType: FieldDataType;
  fieldId: string;
  value: any;
  error?: string;
}

export enum FieldDataType {
  String = 1,
  Numeric = 2,
  Date = 3,
  Boolean = 4,

  FiscalId = 5,
  Series = 6,
  CurrencyExchange = 7,
  LongString = 8,
  OAuth20Token = 9,

  StringList = 10,
  ImageUrl = 11,

  SingleOption = 98,
  MultiOption = 99,

  ExporterRule = 100,

  Document = 999
}

export const FieldDataTypes = [
  FieldDataType.String,
  FieldDataType.LongString,
  FieldDataType.Numeric,
  FieldDataType.Date,
  FieldDataType.Boolean,

  FieldDataType.FiscalId,
  FieldDataType.Series,
  FieldDataType.CurrencyExchange,

  // FieldDataType.StringList,
  FieldDataType.SingleOption,
  // FieldDataType.MultiOption,
  FieldDataType.Document,
];

export const ComplexFieldDataTypesFields: {[key: number]: Array<DynamicField>} = {
  [FieldDataType.FiscalId]: [
    {fieldId: "isVat", fieldType: FieldDataType.Boolean},
    {fieldId: "cui", fieldType: FieldDataType.String, isRequired: true},
    {fieldId: "j", fieldType: FieldDataType.String},
    {fieldId: "name", fieldType: FieldDataType.String},
    {fieldId: "caen", fieldType: FieldDataType.String},
    {fieldId: "country", fieldType: FieldDataType.String},
    {fieldId: "region", fieldType: FieldDataType.String},
    {fieldId: "city", fieldType: FieldDataType.String},
    {fieldId: "zipCode", fieldType: FieldDataType.String},
    {fieldId: "line1", fieldType: FieldDataType.String},
    {fieldId: "phoneNumber", fieldType: FieldDataType.String},
    {fieldId: "email", fieldType: FieldDataType.String},
    {fieldId: "web", fieldType: FieldDataType.String}],
  [FieldDataType.CurrencyExchange]: [
    {fieldId: "valueFrom", fieldType: FieldDataType.Numeric, isRequired: true},
    {fieldId: "currencyFrom", fieldType: FieldDataType.String, isRequired: true},
    {fieldId: "exchangeDate", fieldType: FieldDataType.Date, isRequired: true},
    {fieldId: "exchangeRate", fieldType: FieldDataType.Numeric, isRequired: true},
    {fieldId: "valueTo", fieldType: FieldDataType.Numeric, isRequired: true},
    {fieldId: "currencyTo", fieldType: FieldDataType.String, isRequired: true}],
  [FieldDataType.Series]: [
    {fieldId: "seriesId", fieldType: FieldDataType.String, isRequired: true},
    {fieldId: "increment", fieldType: FieldDataType.String, isRequired: true}
  ],
  [FieldDataType.ExporterRule]: [
    {fieldId: "seriesId", fieldType: FieldDataType.String, isRequired: true},
    {fieldId: "stuff", fieldType: FieldDataType.Numeric, isRequired: true}
  ]
};
