<app-modal-wrapper [title]="title"
                   [hasClose]="hasClose"
                   [hasHelp]="!!tourId"
                   [hasShortcut]="!!onShortcutRequest"
                   [hasFooter]="(!!form && !!submitCaption) || !!actions"
                   (close)="dismiss()"
                   (help)="handleHelp()"
                   (shortcut)="onShortcutRequest.apply(null)">
  <ng-container content>
    <ng-container *ngComponentOutlet="itemComponent;inputs: dynamicInputs;"/>
  </ng-container>

  <ng-container footer>
    @if (loadState?.isWorking) {
      <app-preloader class="overlay-absolute"></app-preloader>
    }
    <div class="flex-grow-1 flex-md-grow-0">
      @if (form?.value && submitCaption && loadState?.isIdle) {
        @if (submitProps?.buttonType === 'normal' || !submitProps?.buttonType) {
          <ion-button [formGroup]="$any(form)"
                      class="w-100"
                      [color]="submitProps?.color"
                      [disabled]="form?.invalid"
                      (click)="submit()">
            @if (submitProps?.icon) {
              <ion-icon slot="start" [name]="submitProps.icon"></ion-icon>
            }
            <ion-label [translate]="submitCaption"></ion-label>
          </ion-button>
        } @else if (submitProps?.buttonType === 'fab') {
          <ion-fab-button [formGroup]="$any(form)"
                          [color]="submitProps?.color"
                          [disabled]="form?.invalid"
                          (click)="submit()">
            @if (submitProps?.icon) {
              <ion-icon [name]="submitProps.icon"></ion-icon>
            }
          </ion-fab-button>
        }
      }
    </div>
    @if (actions) {
      <app-action-bar class="d-block ml-1"
                      [type]="ActionBarType.Drop"
                      [actions]="actions"
                      [dropOptions]="{collapseOnCount: 3}">
      </app-action-bar>
    }
  </ng-container>
</app-modal-wrapper>
